import { env } from './env';

export const constants = {
	APP_TITLE_TEMPLATE: '%s | frachter',
	COOKIE_NAME_COLOR_SCHEME: 'color_scheme',
	COOKIE_NAME_SANDBOX_INSTALLATION: 'sandbox_installation',
	DEFAULT_ORGANIZATION_APP: 'quickship',
	HEADER_KEY_ORG_SLUG: 'x-org-slug',
	NOTIFICATION_ID_PRINTER_GONE: 'printer-gone',
	NOTIFICATION_REALTIME_TOKEN_FETCH_FAILED: 'realtime-token-fetch-failed',
	PROXY_URL: env.NEXT_PUBLIC_SITE_URL + '/api/proxy',
	SAMPLE_PDF_URL: 'https://www.frachter.app/static/dummy/print-test-1.pdf',
	SEARCH_PARAM_NAME_LOGIN: 'login',
	// SESSION_STORAGE_KEY_ORGANIZATIONS: 'organizations',
	SEARCH_PARAM_NAME_URL_RECOVERY: 'next',
	STORAGE_KEY_ORGANIZATION_id: 'org_id',
	SUPERUSER_IDS: ['user_2gILoDQ475bV1lE1aKbJ9Qaw6ry'] as string[],
} as const;
