'use client';

import { getCookie } from 'cookies-next';
import { useSelectedLayoutSegments } from 'next/navigation';
import { PropsWithChildren } from 'react';

import { constants } from '@/data/constants';

export const Html: React.FC<PropsWithChildren> = ({ children }) => {
	const segments = useSelectedLayoutSegments();

	let scheme = (getCookie(constants.COOKIE_NAME_COLOR_SCHEME) as 'light' | 'dark' | undefined) ?? undefined;

	if (['(authenticated)', '(public)'].includes(segments[0])) {
		scheme = 'dark';
	} else {
		scheme = scheme ?? 'dark';
	}

	return (
		<html data-mantine-color-scheme={scheme} dir="ltr" lang="de" suppressHydrationWarning>
			{children}
		</html>
	);
};
